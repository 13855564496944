import React, { useState } from 'react';
import { MagnifyingGlass } from 'react-loader-spinner';
import { track } from "@amplitude/analytics-browser";

import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import ModalAlert from '../../components/modalAlert/ModalAlert';

import bookCompassImg from '../../images/book-compass.png';

import bookCompassService from '../../services/book-compass.service';

export default function BookCompass() {
  const [form, setForm] = useState({
    want_to_learn: "",
    want_to_apply_in: ""
  });
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setAPIError] = useState(false);
  const [suggestedBookList, setSuggestedBookList] = useState([]);
  const [showInputAlert, setShowInputAlert] = useState(false);
  const [showRespAlert, setShowRespAlert] = useState(false);
  const [showSummaryAlert, setShowSummaryAlert] = useState(false);
  const [bookSummaryData, setBookSummaryData] = useState('');

  const handleFormInputChange = (e) => {
    e.preventDefault();
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  }

  const handleFindBooks = async (e) => {
    track("Book Compass - Find Books button clicked", {
      "event_type": "BookCompass"
    });

    e.preventDefault();

    // to prevent duplicate calls
    if (isLoading) {
      setShowRespAlert(true);
      return;
    }

    setIsLoading(true);

    // to check if form inputs are provided
    if (!form.want_to_learn || !form.want_to_apply_in) {
      setShowInputAlert(true);
      setIsLoading(false);
      return;
    }

    // API call
    const result = await bookCompassService.suggestBook({ want_to_learn: form.want_to_learn, want_to_apply_in: form.want_to_apply_in }).catch(error => {
      setAPIError(true);
      setIsLoading(false);
      return;
    });
    if (!result) {
      return;
    }else{
      setSuggestedBookList(result.data);
      setIsLoading(false);
      setForm({
        want_to_learn: "",
        want_to_apply_in: ""
      });
    }
  }

  const handleSummarize = async (book) => {
    track("Book Compass - Summary button clicked", {
      "event_type": "BookCompass"
    });

    const result = await bookCompassService.summarizeBook({ book_title: book.bookTitle, book_author: book.author }).catch(error => {
      setAPIError(true);
      setIsLoading(false);
      return;
    });
    if (!result) {
      return;
    }else{
      setShowSummaryAlert(true);
      setBookSummaryData(result.data);
    }
  }

  return (
    <>
      <Navbar />
      <div className="mx-4 sm:mx-8 md:mx-16 lg:mx-28 px-2">      
        <div className="p-4 text-center font-bold">
          <img src={bookCompassImg} alt="" className="h-16 inline-block" />
          <p className="font-semibold text-lg inline">Book Compass</p>
        </div>
        <div className="border-4 p-8 m-4 border-[#F9F9F9] text-left hover:shadow-lg">

          <ModalAlert title="Cannot Suggest Book" body="Need input to find books!" isOpen={showInputAlert} onClose={() => setShowInputAlert(false)} type='warning'/>
          <ModalAlert title="Be patient" body="Wait for response!" isOpen={showRespAlert} onClose={() => setShowRespAlert(false)} type='warning'/>
          <ModalAlert title="Book Summary" body={bookSummaryData} isOpen={showSummaryAlert} onClose={() => setShowSummaryAlert(false)} type='info'/>
          <ModalAlert title="Unable to suggest" body="Error while submitting the form. Please try again!" isOpen={apiError} onClose={() => setAPIError(false)} type='error'/>

          <form className='' onSubmit={handleFindBooks}>
            <div className="my-6">
              <label className='font-semibold text-[18px]'>I wanted to learn:</label>
              <input className='my-2 shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline' type="text" name="want_to_learn" placeholder='marketing' onChange={handleFormInputChange} value={form.want_to_learn}/>
            </div>
            <div className="my-6">
              <label className='font-semibold text-[18px]'>I wanted to apply my knowledge in:</label>
              <input className='my-2 shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline' type="text" name="want_to_apply_in" placeholder='startup' onChange={handleFormInputChange} value={form.want_to_apply_in}/>
            </div>
            <button disabled={isLoading ? true : false} type='submit' className="bg-[#000000] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
              Find Books
            </button>
          </form>
          {isLoading ?
            <>
              <hr className='my-12' />
              <div className='my-2 flex align-center justify-center'>
                <MagnifyingGlass
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="MagnifyingGlass-loading"
                  wrapperStyle={{}}
                  wrapperClass="MagnifyingGlass-wrapper"
                  glassColor='#f9f9f9'
                  color='#000000'
                />
              </div>
            </> :
            <>
              {/* <hr className='my-12'/> */}
              {suggestedBookList.length ? <h1 className='px-2 py-6 my-8 rounded-md flex justify-center font-semibold text-[20px] bg-yellow-100'>These are few books you can read</h1> : <></>}
              <div>
                {
                  suggestedBookList.map((item, index) => {
                    return (
                      <>
                        <div className='order-first flex flex-wrap' key={index}>
                          <div className="w-full px-4 md:w-1/3 mb-3 flex justify-center items-center my-3">
                            <img className='h-[280px] w-[180px] border' src={`https://covers.openlibrary.org/b/isbn/${item.isbn}-M.jpg`} alt={`${item.bookTitle} cover`} />
                          </div>
                          <div className="w-full px-4 md:w-2/3 my-3">
                            <div className='py-2'>
                              <b>Title</b>: {item.bookTitle}
                            </div>
                            <div className='py-2'>
                              <b>Author</b>: {item.author}
                            </div>
                            <div className='py-2'>
                              <b>ISBN</b>: {item.isbn}
                            </div>
                            <button className='bg-[#303030] text-white font-bold my-2 py-2 px-4 rounded focus:outline-none focus:shadow-outline' onClick={() => {handleSummarize(item)}}>Summarize</button>
                          </div>
                        </div>
                        <hr className='my-4 '/>
                      </>
                    )
                  })
                }
              </div>
            </>
          }
        </div>
      </div>
      <Footer />
    </>
  );
}
