import axios from "axios";
import { headerKey } from "./api-header";
import config  from '../config/config.js';

const API_BASE_URL = config.REACT_APP_API_DOMAIN;

const suggestBook = async (payload) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/book-compass/suggest`, payload,
    {
      headers: { ...headerKey }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

const summarizeBook = async (payload) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/book-compass/summary`, payload,
    {
      headers: { ...headerKey }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

const bookCompassService = {
  suggestBook,
  summarizeBook
};

export default bookCompassService;
