import React, { useState } from 'react';
import { track } from "@amplitude/analytics-browser";

import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';


const Bmi = () => {
  track("Bmi - Bmi page viewed", {
    "event_type": "Bmi"
  });

  const [weight, setWeight] = useState(0);
  const [height, setHeight] = useState(0);
  const [system, setSystem] = useState('metric'); // 'metric' or 'imperial'

  const handleWeightChange = (event) => {
    setWeight(parseFloat(event.target.value));
  };

  const handleHeightChange = (event) => {
    setHeight(parseFloat(event.target.value));
  };

  const calculateBMI = () => {
    if (system === 'metric') {
      return weight / height ** 2;
    } else {
      // Convert pounds to kilograms and feet to meters before calculation
      const weightInKg = weight * 0.453592;
      const heightInMeters = height * 0.3048;
      return weightInKg / heightInMeters ** 2;
    }
  };

  const [isChecked, setIsChecked] = useState(false)

  const handleCheckboxChange = () => {
    setSystem(system === 'metric' ? 'imperial' : 'metric');

    const newWeight = system === 'metric' ? weight / 0.453592 : weight * 0.453592 ;
    const newHeight = system === 'metric' ? height / 0.3048 : height * 0.3048 ;
    setWeight(newWeight);
    setHeight(newHeight);
    setIsChecked(!isChecked);
  }

  return (
    <>
    <Navbar />
    <div className="mx-6 sm:mx-10 md:mx-18 lg:mx-28 px-2">
      <div className="p-4 flex flex-col items-center">
        <h1 className="text-xl font-semibold mb-4">BMI Calculator</h1>
        <div className="mb-4">          
          <label className='themeSwitcherTwo shadow-card relative inline-flex cursor-pointer select-none items-center justify-center rounded-md bg-white p-1'>
            <input type='checkbox' className='sr-only' checked={isChecked} onChange={handleCheckboxChange}/>
            <span className={`flex items-center space-x-[6px] rounded py-2 px-[18px] text-sm font-medium ${!isChecked ? 'text-primary bg-[#F4F7FF]' : 'text-body-color'}`}>
              Metric
            </span>
            <span className={`flex items-center space-x-[6px] rounded py-2 px-[18px] text-sm font-medium ${isChecked ? 'text-primary bg-[#F4F7FF]' : 'text-body-color'}`}>
              Imperial
            </span>
          </label>

        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">
            Weight ({system === 'metric' ? 'kg' : 'lbs'}):
          </label>
          <input
            type="number"
            step="any"
            value={weight}
            onChange={handleWeightChange}
            className="form-input px-4 py-2 border rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">
            Height ({system === 'metric' ? 'meters' : 'feet'}):
          </label>
          <input
            type="number"
            step="any"
            value={height}
            onChange={handleHeightChange}
            className="form-input px-4 py-2 border rounded"
          />
        </div>
        <div className="mb-4 text-center">
          Your BMI is: <strong>{!isNaN(calculateBMI().toFixed(2)) ? calculateBMI().toFixed(2) : '--'}</strong>
        </div>
      </div>
    </div>
    <Footer />
  </>
  );
};

export default Bmi;
