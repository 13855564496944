export default function Footer() {
  return (
    <>
      <footer className="bg-[#F9F9F9] mt-12">
        <div className="max-w-8xl mx-auto px-5 pt-16 pb-8 text-black mt-4">
          <div className="order-first flex flex-wrap text-left">
            <div className="w-full px-4 sm:w-2/4 lg:w-1/5 mb-3">
              <h2 className="mb-2 text-lg tracking-widest">Site Map</h2>
              <nav className="list-none space-y-2 py-3">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/about-me">About-Me</a>
                </li>
                <li>
                  <a href="/fund-me">Fund-Me</a>
                </li>
              </nav>
            </div>
            <div className="w-full px-4 sm:w-2/4 lg:w-1/5 mb-3">
              <h2 className="mb-2 text-lg tracking-widest">Follow Me</h2>
              <nav className="mb-10 list-none space-y-2 py-3">
                <li>
                  <a href="https://twitter.com/qubetools" target="_blank" rel="noreferrer">Twitter</a>
                </li>
                <li>
                  <a href="https://youtube.com/qubetools" target="_blank" rel="noreferrer">Youtube</a>
                </li>
                <li>
                  <a href="https://www.instagram.com/qubetools" target="_blank" rel="noreferrer">Instagram</a>
                </li>
                <li>
                  <a href="https://www.reddit.com/user/qubetools" target="_blank" rel="noreferrer">Reddit</a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/in/qubetools" target="_blank" rel="noreferrer">LinkedIn</a>
                </li>
              </nav>
            </div>
          </div>
        </div>
        <hr />
        <div className="px-2">
          <div className="max-w-8xl mx-auto px-5 py-6">
            <h2 className="text-black text-sm">© 2023 QubeTools. All rights reserved.</h2>
          </div>
        </div>
      </footer>
    </>
  );
}