import React, { useState } from 'react';

import cubePNG from '../../images/cude_white_small.png';

export default function Navbar() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const handleMobileMenuClick = () => {
    setShowMobileMenu(!showMobileMenu);
  }

  return (
    <>
      <div className="flex items-center justify-between py-4 sm:mx-0 mb-4 sm:px-0 md:px-6">
        <div className="mt-4 inline-block pb-4 pl-8">
        <button className="pr-8 pl-4" onClick={handleMobileMenuClick}>
            <svg
              className="mr-auto inline-block text-black md:hidden"
              width="33"
              height="50"
              viewBox="0 0 23 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.892578 10.8691H22.1058"
                stroke="black"
                strokeLinecap="square"
                strokeLinejoin="round"
              />
              <path
                d="M0.892578 18.8691H22.1058"
                stroke="black"
                strokeLinecap="square"
                strokeLinejoin="round"
              />
              <path
                d="M22.1066 14.8688H0.893399"
                stroke="black"
                strokeLinecap="square"
                strokeLinejoin="round"
              />
            </svg>
        </button>

          <a href="/" className="align-middle text-3xl font-bold text-black">QubeTools</a>
          <img src={cubePNG} alt="" className="h-16 inline-block" />
        </div>

        <div className="flex items-center">
          <div className="hidden py-1 text-right md:inline-block">
            <a className="mt-2 inline-flex items-center px-8 py-3 text-lg font-semibold tracking-tighter text-black" href="/">Home</a>
            <a className="mt-2 inline-flex items-center px-8 py-3 text-lg font-semibold tracking-tighter text-black" href="/about-me">About-Me</a>
            <a className="mt-2 inline-flex items-center px-8 py-3 text-lg font-semibold tracking-tighter text-black" href="/fund-me">Fund-Me</a>
          </div>
        </div>
      </div>

      <hr className='mb-6'></hr>

      <div className={`${'z-999 absolute bg-white font-semibold overflow-hidden w-full transition-[max-height] shadow-md md:hidden'} ${showMobileMenu ? 'max-h-full' : 'max-h-0'}`}>
        {[
          { title: 'Home', link: '/' },
          { title: 'About-Me', link: '/about-me' },
          { title: 'Fund-Me', link: '/fund-me' },
        ].map((item, index) => (
            <a href={item?.link} key={index}>
              <div className="m-[20px] px-[20px] py-[2px] border-1" key={index} onClick={() => setShowMobileMenu(!showMobileMenu)}>
                <span>{item?.title}</span>
              </div>
            </a>
        ))}
      </div>
    </>
  );
}
