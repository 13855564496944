import React from "react";
import { useNavigate } from "react-router-dom";
import { track } from "@amplitude/analytics-browser";

import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';

import bookCompassImg from '../../images/book-compass.png';
import bmiImg from '../../images/bmi.png';

const LandingPage = () => {
  track("LandingPage - Landing page viewed", {
    "event_type": "LandingPage"
  });

  const navigate = useNavigate();

  const handlBookCompassButton = () => {
    track("LandingPage - Book Compass button clicked", {
      "event_type": "LandingPage"
    });

    navigate("/book-compass");
  }

  const handlBMIButton = () => {
    track("LandingPage - BMI button clicked", {
      "event_type": "LandingPage"
    });
    navigate("/bmi");
  }

  return (
  <>
    <Navbar />
    <div className="mx-6 sm:mx-10 md:mx-18 lg:mx-28 px-2">
      <div className="p-4">
        <img src={bookCompassImg} alt="" className="h-14 inline-block" />
        <p className="font-semibold text-lg inline">Book Compass</p>
      </div>
      <div className="border-4 p-8 m-4 border-[#F9F9F9] text-left hover:shadow-lg">
        <p>
          A simple tool to <b>find the books you wanna read</b> based on what you want to learn and where you want to apply the knowledge.
        </p>
        <p>
          You can read summary and get the feel before deciding it to read fully.
        </p>
        <br></br>
        <button className="bg-[#000000] text-[#ffffff] font-semibold px-4 py-2 rounded-md block" onClick={handlBookCompassButton}>Check it out</button>
      </div>

      <div className="p-4">
        <img src={bmiImg} alt="" className="h-14 inline-block" />
        <p className="font-semibold text-lg inline">BMI</p>
      </div>
      <div className="border-4 p-8 m-4 border-[#F9F9F9] text-left hover:shadow-lg">
        <p>
          A simple tool to calculate the BMI in Metric or Imperial system.
        </p>
        <br></br>
        <button className="bg-[#000000] text-[#ffffff] font-semibold px-4 py-2 rounded-md block" onClick={handlBMIButton}>Calculate</button>
      </div>
    </div>
    <Footer />
  </>
  );
}

export default LandingPage;
