import { useEffect } from 'react';
import {BrowserRouter as Router, useRoutes } from "react-router-dom";

import './App.css';

import * as amplitude from '@amplitude/analytics-browser';

import config  from './config/config.js';

import LandingPage from './pages/landingPage/LandingPage';
import AboutMe from './pages/aboutMe/AboutMe';
import FundMe from './pages/fundMe/FundMe';
import BookCompass from './pages/bookCompass/BookCompass';
import Bmi from './pages/bmi/Bmi';

const amplitudeKey = config.REACT_APP_AMPLITUDE_KEY;

const App = () => {
  let routes = useRoutes([
    { path: "/", element: <LandingPage /> },
    { path: "/about-me", element: <AboutMe /> },
    { path: "/fund-me", element: <FundMe /> },
    { path: "/book-compass", element: <BookCompass /> },
    { path: "/bmi", element: <Bmi /> }
    // ...
  ]);
  return routes;
};

const AppWrapper = () => {
  useEffect(() => {
    amplitude.init(amplitudeKey);
  }, []);

  return (
    <Router>
      <App />
    </Router>
  );
};

export default AppWrapper;
