import React from "react";
import { track } from "@amplitude/analytics-browser";

import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';

import aboutMe from '../../images/aboutMe.png';
import socialImg from '../../images/social.png';

const AboutMe = () => {
  track("AboutMe - AboutMe page viewed", {
    "event_type": "AboutMe"
  });

  const social = [
    {
      icon: 'https://qubetools-public.s3.us-east-2.amazonaws.com/twitter.png',
      url: "https://twitter.com/qubetools",
      alt: "Qubetools Twitter"
    },
    {
      icon: 'https://qubetools-public.s3.us-east-2.amazonaws.com/youtube.png',
      url: "https://youtube.com/qubetools",
      alt: "Qubetools Youtube"
    },
    {
      icon: 'https://qubetools-public.s3.us-east-2.amazonaws.com/instagram.png',
      url: "https://www.instagram.com/qubetools",
      alt: "Qubetools Instagram"
    },
    {
      icon: 'https://qubetools-public.s3.us-east-2.amazonaws.com/reddit.png',
      url: "https://www.reddit.com/user/qubetools",
      alt: "Qubetools Reddit"
    },
    {
      icon: 'https://qubetools-public.s3.us-east-2.amazonaws.com/linkedin.png',
      url: "https://www.linkedin.com/in/qubetools",
      alt: "Qubetools LinkedIn"
    }
  ];

  return (
  <>
    <Navbar />
    <div className="mx-6 sm:mx-10 md:mx-18 lg:mx-28 px-2">
      <div className="p-4">
        <img src={aboutMe} alt="" className="h-14 inline-block" />
        <p className="font-semibold text-lg inline">About me</p>
      </div>
      <div className="border-4 p-8 m-4 border-[#F9F9F9] order-first flex flex-wrap text-left hover:shadow-lg">
        <p>
          I do boring stuff most of the time and then I realised that I should start creating things, so here I am.
          <br></br>
          <br></br>
          You can follow me on these platforms, feel free to <b>leave the feedback</b> on my <b>Twitter</b> or <b>Instagram</b>.
        </p>
      </div>

      <div className="p-4">
        <img src={socialImg} alt="" className="h-14 inline-block" />
        <p className="font-semibold text-lg inline">Follow me on my following social media</p>
      </div>
      <div className="border-4 p-8 m-4 border-[#F9F9F9] order-first flex flex-wrap text-left hover:shadow-lg">
        {social.map(item => {
          return (
            <div className="border-4 p-8 m-4 bg-[#F9F9F9] text-left hover:shadow-lg">
              <a href={item?.url} target="_blank" rel="noreferrer">
                    <img src={item?.icon} className="h-16" alt={item.alt}></img>
              </a>
            </div>
          )
        })}
      </div>
    </div>
    <Footer />
  </>
  );
}

export default AboutMe;