import React from 'react';

const ModalAlert = ({ title, body, isOpen, onClose, type='info' }) => {
  if (!isOpen) return null;

  return (
    type === 'error' ? 
    <>
      <div className={`bg-black bg-opacity-50 fixed inset-0 z-50 overflow-auto bg-smoke-light flex text-red-600`}>
        <div className="relative p-8 bg-white w-full max-w-md m-auto flex-col flex rounded-lg shadow-lg">
          <div className="text-xl font-bold mb-4">{title}</div>
          <div className="mb-8">
            <p>{body}</p>
          </div>
          <div className="flex justify-end">
            <button onClick={onClose} className={`px-4 py-2 text-white text-base font-medium rounded-md w-auto shadow-sm bg-red-700 hover:bg-red-600`}>
            Close
            </button>
          </div>
        </div>
      </div>
    </> 
    
    : 
    type === 'warning' ? 
    <>
      <div className={`bg-black bg-opacity-50 fixed inset-0 z-50 overflow-auto bg-smoke-light flex text-yellow-600`}>
        <div className="relative p-8 bg-white w-full max-w-md m-auto flex-col flex rounded-lg shadow-lg">
          <div className="text-xl font-bold mb-4">{title}</div>
          <div className="mb-8">
            <p>{body}</p>
          </div>
          <div className="flex justify-end">
            <button onClick={onClose} className={`px-4 py-2 text-white text-base font-medium rounded-md w-auto shadow-sm bg-yellow-700 hover:bg-yellow-600`}>
            Close
            </button>
          </div>
        </div>
      </div>
    </> 

    : 

    <>
      <div className={`bg-black bg-opacity-50 fixed inset-0 z-50 overflow-auto bg-smoke-light flex text-slate-600`}>
        <div className="relative p-8 bg-white w-full max-w-md m-auto flex-col flex rounded-lg shadow-lg">
          <div className="text-xl font-bold mb-4">{title}</div>
          <div className="mb-8">
            <p>{body}</p>
          </div>
          <div className="flex justify-end">
            <button onClick={onClose} className={`px-4 py-2 text-white text-base font-medium rounded-md w-auto shadow-sm bg-slate-700 hover:bg-slate-600`}>
            Close
            </button>
          </div>
        </div>
      </div>
    </> 


  );
};

export default ModalAlert;
