import React from "react";
import { track } from "@amplitude/analytics-browser";

import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';

import qtBmcQr from '../../images/qt_bmc_qr.png';

const FundMe = () => {
  track("FundMe - FundMe page viewed", {
    "event_type": "FundMe"
  });

  return (
  <>
    <Navbar />
    <div className="mx-6 sm:mx-10 md:mx-18 lg:mx-28 px-2">
      <div className="border-4 p-8 m-4 border-[#F9F9F9] order-first flex flex-wrap text-left hover:shadow-lg">
        <div className="w-full px-4 md:w-2/4 lg:w-1/2 mb-3 justify-center">
          <img src={qtBmcQr} className="h-[160px] sm:h-[240px] md:h-[320px] w-[160px] sm:w-[240px] md:w-[320px] my-4" alt="Buy Me a coffee QR"></img>
        </div>
        <div className="w-full px-4 md:w-2/4 lg:w-1/2 my-3">
          <p>
          I do boring stuff.
          </p>
          <br></br>
          <p>
          <b>Beware of fraud</b>: My correct handle - @qubetools ( all in lowercase with "s" at the end )
          </p>
          <a href="https://www.buymeacoffee.com/qubetools" target="_blank" className="m-2" rel="noreferrer">
            <img src="https://cdn.buymeacoffee.com/buttons/v2/default-green.png" alt="Buy Me A Coffee | QubeTools" className="h-[70px] w-[240px]" />
          </a>
        </div>
      </div>
    </div>
    <Footer />
  </>
  );
}

export default FundMe;